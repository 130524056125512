import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import client from '../contentful/client'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/intro'
import Graphic from '../components/graphic'
import ImageContainer from '../components/imagesContainer'
import Image from '../components/image'

function getLandingPageData() {
  return client.getEntry('CxKsJUaq6GVE6dYmsmiR0').then(entry => {
    const { intro } = entry.fields
    const graphic = `https:${entry.fields.graphic.fields.file.url}`
    const images = entry.fields.images.map(img => ({
      src: `https:${img.fields.file.url}`,
      vertical: img.fields.file.details.image.width > img.fields.file.details.image.height
    }))

    return { intro, graphic, images }
  })
}

const IndexPage = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    getLandingPageData().then(setData)
  }, [])

  return (
    <Layout>
      <SEO title="rtsttrs" />
      <Container>
        {data && (
          <>
            <Row>
              <Col>
                <Intro graphic={<Graphic src={data.graphic} />}>{data.intro}</Intro>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ImageContainer>
                  {data.images.map(img => (
                    <Image src={img.src} key={img.src} vertical={img.vertical} />
                  ))}
                </ImageContainer>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Layout>
  )
}

export default IndexPage
