import React from 'react'
import { oneOfType, arrayOf, node } from 'prop-types'

import styles from './imagesContainer.module.css'

const ImageContainer = ({ children }) => <div className={styles.container}>{children}</div>

ImageContainer.propTypes = {
  children: oneOfType([node, arrayOf(node)])
}

ImageContainer.defaultProps = {
  children: null
}

export default ImageContainer
