import React, { useRef, useEffect } from 'react'
import { string, bool } from 'prop-types'
import classnames from 'classnames'

import styles from './image.module.css'

function getObserver({ onVisible }) {
  return new IntersectionObserver((entries) => {
    const entry = entries[0]
    if (entry.intersectionRatio > 0) { // is in viewport
      onVisible()
    } else {
      // is not in viewport
    }
  }, {
    threshold: [0, 0.25, 0.5, 0.75, 1],
    rootMargin: '0px 0px 300px 0px'
  })
}

function makeImageVisible(img) {
  img.src = img?.dataset?.src
}

const Image = ({ src, className, alt, vertical }) => {
  const img = useRef(null)

  useEffect(() => {
    if (!img.current) {
      return
    }

    if (typeof IntersectionObserver !== 'function') {
      makeImageVisible(img.current)
      return
    }

    const observer = getObserver({ onVisible: () => makeImageVisible(img.current) })

    observer.observe(img.current)

    // Cleanup
    return () => {
      observer.disconnect()
    }
  }, [img.current])

  return (
    <img
      className={classnames(styles.image, vertical ? styles.vertical : '', className)}
      ref={img}
      data-src={src}
      alt={alt}
    />
  )
}

Image.propTypes = {
  src: string.isRequired,
  vertical: bool,
  className: string,
  alt: string
}

Image.defaultProps = {
  className: '',
  vertical: false,
  alt: 'image'
}

export default Image
