import React, { Component } from 'react'
import { node, oneOfType, array } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import styles from './intro.module.css'

class Intro extends Component {
  static STICKY_ID = 'sticky'

  constructor(props) {
    super(props)
    this.isStickyInitialized = false
  }

  componentDidMount() {
    if (window && document) {
      window.scrollTo(0, 0)
      this.initSticky()
      window.addEventListener('scroll', () => this.initSticky())
      window.addEventListener('resize', () => (this.isStickyInitialized = false))
    }
  }

  componentWilUnmount() {
    window.removeEventListener('scroll', () => this.initSticky())
    window.removeEventListener('resize', () => (this.isStickyInitialized = false))
  }

  initSticky = () => {
    if (this.isStickyInitialized) return

    const sticky = document.getElementById(Intro.STICKY_ID)
    const { bottom, top } = sticky.getBoundingClientRect()
    const diff = window.innerHeight - bottom

    if (diff >= 10) {
      sticky.style.top = `${top}px`
      this.isStickyInitialized = true
    }
  }

  render() {
    const { children, graphic } = this.props

    return (
      <div className={styles.intro}>
        <Row id={Intro.STICKY_ID} className={styles.sticky}>
          <Col>{children}</Col>
        </Row>
        <Row className={styles.graphicContainer}>
          <Col>{graphic}</Col>
        </Row>
      </div>
    )
  }
}

Intro.propTypes = {
  children: oneOfType([array, node]).isRequired,
  graphic: node
}

Intro.defaultProps = {
  graphic: null
}

export default Intro
