import React from 'react'
import { string } from 'prop-types'

import styles from './graphic.module.css'

export const Graphic = ({ src }) => <img className={styles.image} src={src} />

Graphic.propTypes = {
  src: string.isRequired
}

export default Graphic
